import React from 'react';

import { Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import {
    INTEREST_RATE_TYPE,
    FREQUENCY_LABEL,
    TERM,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatPercentage } from 'utils';
import { useIsTenant } from 'utils/useIsTenant';

import styles from './section-details.module.scss';

export const MortgageRequest = ({ submissionNotes }: SectionDetailsProps) => {
    const { i18n } = useI18n();
    const { name } = submissionNotes?.applicationFields?.product || {};

    const {
        purposeDescription,
        lenderName,
        frequency,
        amortizationMonths,
        amortizationYears,
        insurable,
        bestRate,
        baseRate,
        buydownRate,
        type,
        term,
        isBlendedRate,
    } = submissionNotes?.applicationFields?.mortgage || {};

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    return (
        <Grid className={styles.grid}>
            <EditableCell
                name="purposeDescription"
                fieldType="readonly"
                label="purpose"
                value={purposeDescription}
            />
            <EditableCell
                name="lenderName"
                fieldType="readonly"
                label="lender"
                value={lenderName}
            />
            <EditableCell
                name="term"
                fieldType="readonly"
                label="term"
                value={TERM(i18n).find((_term) => _term.value === term)?.label}
            />
            <EditableCell
                name="productName"
                fieldType="readonly"
                label="productLabel"
                value={name}
            />
            {isIGTenant && (
                <EditableCell
                    name="isBlendedRate"
                    fieldType="readonly"
                    label="isBlendedRate"
                    value={isBlendedRate}
                />
            )}
            <EditableCell
                name="type"
                fieldType="readonly"
                label="type"
                value={INTEREST_RATE_TYPE(i18n)[type]}
            />
            <EditableCell
                name="frequency"
                fieldType="readonly"
                label="paymentFrequency"
                value={FREQUENCY_LABEL(i18n)[frequency]}
            />
            <EditableCell
                name="amortizationYears"
                fieldType="readonly"
                label="amortizationYears"
                value={amortizationYears}
            />
            <EditableCell
                name="amortizationMonths"
                fieldType="readonly"
                label="amortizationMonths"
                value={amortizationMonths}
            />

            <EditableCell
                name="insurable"
                fieldType="readonly"
                label="insurableType"
                value={insurable}
            />
            <EditableCell
                name="baseRate"
                fieldType="readonly"
                label="baseRate"
                value={formatPercentage(baseRate)}
            />
            <EditableCell
                name="buydownRate"
                fieldType="readonly"
                label="buyDownRate"
                value={formatPercentage(buydownRate)}
            />
            <EditableCell
                name="bestRate"
                fieldType="readonly"
                label="bestRate"
                value={formatPercentage(bestRate)}
            />
        </Grid>
    );
};
