import { FieldValues } from 'react-hook-form';
import { ValidationError } from 'yup';

import { LenderId } from 'constants/lenderConstants';
import {
    SECTIONS,
    REMOVED_SECTIONS_ID,
    SUBMISSION_NOTES_IMPORTANT_FIELDS,
    STANDALONE_HELOC_SUBMISSION_NOTES_SECTIONS_MENU,
    HELOC_SUBMISSION_NOTES_SECTIONS_MENU,
    PRE_APP_SUBMISSION_NOTES_SECTIONS_MENU,
    READVANCE_SUBMISSION_NOTES_SECTIONS_MENU,
    SUBMISSION_NOTES_SECTIONS_MENU,
    SUBMIT_SUBMISSION_NOTES_MISSING_FIELDS,
} from 'constants/submission-notes';
import { Applicant } from 'types/applicant';
import { Application, ApplicationType } from 'types/application';
import { HelocApplication } from 'types/heloc';
import {
    CustomFields,
    SECTION,
    SectionId,
    SectionKey,
    SubmissionNoteType,
} from 'types/submission-notes';
import { booleanNormalizer } from 'utils';

import * as utils from '.';

export const getActiveTabIndex = (
    _applicantId: number,
    applicants: Applicant[]
) => applicants.findIndex(({ applicantId }) => applicantId === _applicantId);

export const isTwoColumnsSectionLayout = (
    sectionId: SectionId,
    isRelatedDocumentsVisible: boolean
) => {
    const sectionsWithDynamicLayout: SectionId[] = [
        SECTION.MORTGAGE_REQUEST,
        SECTION.SUBJECT_PROPERTY,
        SECTION.TRANSACTION_DETAILS,
    ];

    return sectionsWithDynamicLayout.includes(sectionId)
        ? isRelatedDocumentsVisible
        : true;
};

const isNestoLendingSectionSkipped = (lenderId: number) =>
    lenderId === LenderId.NESTO || lenderId === LenderId.IG;

export const getCoApplicantSections = () => {
    const visibleSectionsId: SectionId[] = [
        SECTION.INCOME,
        SECTION.ASSETS,
        SECTION.OWNED_PROPERTIES,
    ];

    return SECTIONS.filter((section) => visibleSectionsId.includes(section.id));
};

const getRemovedSectionsId = (
    type: ApplicationType,
    hasHelocAmount: boolean,
    hasProduct: boolean
) => {
    if (type === 'PRE_APPROVAL') {
        return REMOVED_SECTIONS_ID.PRE_APPROVAL;
    }
    if (type === 'READVANCE') {
        return REMOVED_SECTIONS_ID.READVANCE;
    }
    if (hasHelocAmount && hasProduct) {
        return REMOVED_SECTIONS_ID.STANDALONE_HELOC;
    }
    if (hasHelocAmount && !hasProduct) {
        return REMOVED_SECTIONS_ID.HELOC;
    }
    return REMOVED_SECTIONS_ID.OTHER;
};

export const getSections = (
    type: ApplicationType,
    lenderId: number,
    hasHelocAmount: boolean,
    hasProduct: boolean
) => {
    const removedSectionsId = getRemovedSectionsId(
        type,
        hasHelocAmount,
        hasProduct
    );

    if (
        isNestoLendingSectionSkipped(lenderId) &&
        !removedSectionsId.includes(SECTION.WHY_NOT_NESTO_LENDING)
    ) {
        removedSectionsId.push(SECTION.WHY_NOT_NESTO_LENDING);
    }
    return SECTIONS.filter(
        (section) => !removedSectionsId.includes(section.id)
    );
};

export const normalizeSubmissionNoteData = (
    customFields: CustomFields,
    values: any,
    type: ApplicationType
) => {
    const {
        assets,
        credit,
        helocAdditionalComments,
        lawyer,
        importantDetails,
        incomes,
        mortgageProtectionInsurance,
        otherProperties,
        transaction,
        whyNotNestoLending,
    } = values || {};

    const normalizedLegalFees = booleanNormalizer(lawyer?.discussedLegalFees);

    const isReadvance = type === 'READVANCE';

    const normalizedData: CustomFields = {
        ...customFields,
        assets,
        credit,
        helocAdditionalComments,
        importantDetails,
        incomes,
        mortgageProtectionInsurance,
        otherProperties,
        transaction,
        whyNotNestoLending,

        lawyer: {
            // HACK: for Readvance, there are no legal fees for the client
            // We need to fix this on the BE to allow without notary and discussedLegalFees
            ...(isReadvance
                ? {
                      notary: 'N/A FEES DO NOT APPLY',
                      discussedLegalFees: true,
                  }
                : { ...lawyer, discussedLegalFees: normalizedLegalFees }),
        },
    };

    return normalizedData;
};

// Checks if there are any missing fields for the submit submission notes section
export const getMissingFields = ({
    values,
    isIGTenant,
    isNestoProduct,
    isHelocAmountPresent,
    isStandAloneHeloc,
}: {
    values: FieldValues;
    isIGTenant: boolean;
    isNestoProduct: boolean;
    isHelocAmountPresent: boolean;
    isStandAloneHeloc: boolean;
}): Partial<Record<SectionKey, string[]>> => {
    const missingFieldsBySection = Object.entries(
        SUBMIT_SUBMISSION_NOTES_MISSING_FIELDS
    ).reduce((sectionsFields, [section, schema]) => {
        try {
            schema.validateSync(values, {
                abortEarly: false,
                context: {
                    isIGTenant,
                    isNestoProduct,
                    isHelocAmountPresent,
                    isStandAloneHeloc,
                },
            });
        } catch (err) {
            if (err instanceof ValidationError) {
                const errorFields = err.inner.map((error) => error.path);

                sectionsFields[section] = errorFields;
            }
        }

        return sectionsFields;
    }, {});

    return missingFieldsBySection;
};

export const sectionHasIncompleteFields = ({
    sectionName,
    values,
    isIGTenant,
    isHelocAmountPresent,
}: {
    sectionName: SectionId;
    values: FieldValues;
    isIGTenant: boolean;
    isHelocAmountPresent: boolean;
}) => {
    const sectionSchema = SUBMISSION_NOTES_IMPORTANT_FIELDS[sectionName];

    // If there are no important fields for this section, exit early
    if (!sectionSchema) {
        return false;
    }

    const hasIncompleteFields = sectionSchema.isValidSync(values, {
        context: {
            isIGTenant,
            isHelocAmountPresent,
        },
    });

    return !hasIncompleteFields;
};

export const getIsPreApproval = (
    selectedApplication: Application,
    submissionNotes?: SubmissionNoteType
) => {
    if (!submissionNotes || submissionNotes.state === 'CREATED') {
        return selectedApplication?.type === 'PRE_APPROVAL';
    }

    return (
        submissionNotes?.applicationFieldsSnapshot?.applicationSubType ===
        'PRE_APPROVAL'
    );
};

export const getSubnotesSections = (
    helocData: HelocApplication,
    selectedApplication: Application,
    submissionNotes?: SubmissionNoteType
) => {
    const isPreApproval = utils.getIsPreApproval(
        selectedApplication,
        submissionNotes
    );

    if (isPreApproval) {
        return PRE_APP_SUBMISSION_NOTES_SECTIONS_MENU;
    }

    const isReadvance = selectedApplication?.type === 'READVANCE';

    if (isReadvance) {
        return READVANCE_SUBMISSION_NOTES_SECTIONS_MENU;
    }

    const isHelocAmountPresent = helocData?.helocAmount;

    if (isHelocAmountPresent) {
        const isStandAloneHeloc =
            isHelocAmountPresent && !selectedApplication?.mortgage?.product;

        return isStandAloneHeloc
            ? STANDALONE_HELOC_SUBMISSION_NOTES_SECTIONS_MENU
            : HELOC_SUBMISSION_NOTES_SECTIONS_MENU;
    }

    return SUBMISSION_NOTES_SECTIONS_MENU;
};

export const getSubmissionNoteChangedAfterSubmitted = (
    submissionNote: SubmissionNoteType,
    application: Application
) => {
    const isNoteSubmitted = submissionNote?.state === 'SUBMITTED';
    const isNoteSubTypePreApproval =
        submissionNote?.applicationFieldsSnapshot?.applicationSubType ==
        'PRE_APPROVAL';
    const noteMatchesApplicationType =
        submissionNote?.applicationFieldsSnapshot?.applicationType ==
        application?.type;

    return (
        isNoteSubmitted &&
        isNoteSubTypePreApproval &&
        !noteMatchesApplicationType // If note type does not match application type it was changed
    );
};
