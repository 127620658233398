import React from 'react';

import { Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import {
    PROPERTY_PURPOSE_LABEL,
    CONSTRUCTION_TYPE_OPTIONS,
} from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatAddress } from 'utils';
import { useIsTenant } from 'utils/useIsTenant';

import styles from './section-details.module.scss';

export const SubjectProperty = ({ submissionNotes }: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { address, occupancy, constructionType, existingMortgageNumber } =
        submissionNotes?.applicationFields?.subjectProperty || {};

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    return (
        <Grid className={styles.grid}>
            <EditableCell
                name="address"
                fieldType="readonly"
                label="address"
                value={formatAddress(address)}
            />
            {isIGTenant && (
                <EditableCell
                    name="existingMortgageNumber"
                    fieldType="readonly"
                    label="existingMortgageNumber"
                    value={existingMortgageNumber}
                />
            )}
            <EditableCell
                name="purpose"
                fieldType="readonly"
                label="Occupancy"
                value={PROPERTY_PURPOSE_LABEL(i18n)[occupancy]}
            />
            <EditableCell
                name="city"
                fieldType="readonly"
                label="City"
                value={address?.city}
            />
            <EditableCell
                name="stateCode"
                fieldType="readonly"
                label="stateCode"
                value={address?.stateCode}
            />
            <EditableCell
                name="postalCode"
                fieldType="readonly"
                label="postalCode"
                value={address?.postalCode}
            />
            <EditableCell
                name="constructionType"
                fieldType="readonly"
                label="constructionType"
                options={CONSTRUCTION_TYPE_OPTIONS(i18n)}
                value={constructionType}
            />
        </Grid>
    );
};
